
import { defineComponent } from "vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import countries from "@/core/data/countries";

export default defineComponent({
    name: "Guest Input Group",
    components: {
    },
    props: {
        propPaxCode: String,
        propPaxType: String,
        propIndex: Number,
        propBirthDate: String,
    },
    setup(props) {

        return {
            libTools,
            countries,
        };
    },
});
