
import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "Transfer Section",
    components: {
    },
    props: {
        propDirection: String,
        propTransferPrice: {},
        propTransferCode: String,
    },
    setup(props, {emit}) {

        function click_TransferPrice() {
            emit("on-transfer-selected", props.propDirection, props.propTransferPrice);
        }

        return {
            click_TransferPrice, libTools,
        };
    },
});
