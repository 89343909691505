const countries = {
    "TYPES" : [
        {Value : '1', Title : 'Türkiye', },
        {Value : '3', Title : 'İngiltere', },
        {Value : '4', Title : 'Fransa', },
        {Value : '5', Title : 'Ukrayna', },
        {Value : '6', Title : 'Rusya', },
        {Value : '7', Title : 'Hollanda', },
        {Value : '8', Title : 'Almanya', },
    ],
};
export default countries;
