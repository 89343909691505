
import {defineComponent, onMounted} from "vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "Hotel-Content",
    components: {
    },
    props: {
        propHotelPrice: {},
        propHotelCode: String,
    },
    setup(props) {

        onMounted(() => {
            //console.log("onMounted HotelContent propHotelPrice: "+JSON.stringify(props.propHotelPrice));
        });

        return {
            libTools,
        };
    }

});
