
import {computed, defineComponent, ref} from "vue";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
    name: "Summary-Flight",
    props: { theFlight : {}, flightDirection: String, },
    setup(props) {

        const  theFlightReservationList = ref({});

        const dataFlightReservationSearchParams = computed(() => {
            const dataComputed = store.getters.getFlightReservationSearchParams;
            return dataComputed;
        });

        const dataFlightReservationList = computed(() => {
            //console.log("getFlightReservationList START");
            const dataComputed = store.getters.getFlightReservationList;
            //console.log("getFlightReservationList dataComputed.data: "+JSON.stringify(dataComputed.data));
            if (dataComputed!==undefined && dataComputed.data!==undefined) {
                setFlightResetvationList(dataComputed.data.result);
            }
            return dataComputed.data;
        });

        function setFlightResetvationList(prmFlightReservationList) {
            theFlightReservationList.value = prmFlightReservationList;
        }

        function getTotalPrice() {
            let rv = 0;
            for (let i=0; i<(props.theFlight as any).thePrice.PaxPrices.length; i++) {
                const eachItem = (props.theFlight as any).thePrice.PaxPrices[i];
                rv+=eachItem.TotalPrice * libTools.method.getPaxCount_By_Result(theFlightReservationList.value, eachItem.PaxType);
            }
            return rv;
        }

        return {
            AppCore, libTools,
            dataFlightReservationSearchParams, dataFlightReservationList, theFlightReservationList,
            getTotalPrice,
        };
    },
});
