
import {defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import {hideModal} from "@/core/helpers/dom";

export default defineComponent({

    setup(props, {emit}) {

        let refModal = ref<null | HTMLElement>(null);

        let theOrder = ref({});
        let theCardInfo = ref({});
        let theBankPosInfo = ref({});

        function showComp(prmCardInfo, prmBankPosInfo, prmAmount, prmCur, prmDescription) {
            console.log("PosGarantiFormModal.vue showComp START "+JSON.stringify(prmCardInfo));
            theCardInfo.value = prmCardInfo;
            theBankPosInfo.value = prmBankPosInfo;
            startPosProcess(prmAmount, prmCur, prmDescription);
        }

        function startPosProcess(prmAmount, prmCur, prmDescription) {
            console.log("location.href: "+location.href);
            console.log("location.host: "+location.host);

            window.addEventListener('message', handleMessage, false);

            let vvPayload = {
                VoucherNo: AppCore.prsCore_Flight.ccvVoucherNo,
                BankPosID: (theBankPosInfo.value as any).BankPosID,
                InstallmentCount: (theBankPosInfo.value as any).InstallmentCount,
                Amount: prmAmount,
                Cur: prmCur,
                Description: prmDescription,
            };

            store.dispatch(Actions.POS_PROCESS_START_PROCESS, vvPayload).then((prmData) => {
                console.log("POS_PROCESS_SEARCH_PROCESS prmData");
                console.log(prmData);
                if (prmData.data!==undefined && prmData.data.HashData!==undefined && prmData.data.HashData!=="") {
                    console.log("Data: "+JSON.stringify(prmData.data));

                    let vvParams =
                            "?orderid=" + prmData.data.OrderID
                            + "&hashdata=" + prmData.data.HashData
                            + "&TerminalID=" + prmData.data.TerminalID
                            + "&MerchantID=" + prmData.data.MerchantID
                            + "&TerminalProvUserID=" + prmData.data.TerminalProvUserID
                            + "&TerminalUserID=" + prmData.data.TerminalUserID
                            + "&InstallmentCount=" + prmData.data.InstallmentCount
                            + "&amount=" + prmAmount
                            + "&cur=" + prmCur
                            + "&companyname=" + getCompanyName()
                            + "&description=" + prmDescription
                            + "&cardholdername=" + (theCardInfo.value as any).CardHolderName
                            + "&cardnumber=" + (theCardInfo.value as any).CardNumber
                            + "&cardexpirymonth=" + (theCardInfo.value as any).CardExpiryMonth
                            + "&cardexpiryyear=" + (theCardInfo.value as any).CardExpiryYear
                            + "&cardcvv=" + (theCardInfo.value as any).CardCvv
                            + "&successurl=" + prmData.data.SuccessUrl
                            + "&errorurl=" + prmData.data.ErrorUrl
                        ;

                    //let vvPath = "/#/apps/sys/reservations/posgarantiform"+vvParams;
                    let vvPath = "/#/PosGarantiForm"+vvParams;
                    console.log("vvPath: "+vvPath);

                    let divPosGarantiFormA = document.getElementById("divPosGarantiFormA") as HTMLElement;

                    let ifrmPosGarantiFormA = document.getElementById("ifrmPosGarantiFormA") as HTMLIFrameElement;

                    if (divPosGarantiFormA.hasChildNodes() && ifrmPosGarantiFormA!==null) {
                        divPosGarantiFormA.removeChild(ifrmPosGarantiFormA);
                    }

                    //ReCreate
                    ifrmPosGarantiFormA = document.createElement("iframe");
                    ifrmPosGarantiFormA.setAttribute("id", "ifrmPosGarantiFormA");
                    ifrmPosGarantiFormA.setAttribute("width", "100%");
                    ifrmPosGarantiFormA.setAttribute("height", "500");
                    divPosGarantiFormA.appendChild(ifrmPosGarantiFormA);
                    ifrmPosGarantiFormA.src = vvPath;
                }
            });
        }

        function getCompanyName () {
            if (location.host.includes("localhost")) {
                return  ""+location.host.replace(":", "_");
            }
            return "Cyprus Royal Tourism";
        }

        function handleMessage(event) {
            if ( event.data!==undefined) {
                console.log("handleMessage A data:  "+event.data);
                if (typeof event.data === "string") {
                    console.log("handleMessage B data:  "+event.data);
                    let vvObj = JSON.parse(event.data);
                    if (vvObj.OrderID!==undefined ) {
                        console.log("handleMessage vvObj:  "+JSON.stringify(vvObj));
                        console.log("handleMessage OrderID:  "+vvObj.OrderID);
                        theOrder.value = vvObj;
                        if ( vvObj.Response=== "Success" || vvObj.Response=== "Decline") {
                            hideModal(refModal.value);
                            endProcess({});
                        }

                    }
                }

            }
        }

        function endProcess(prmData) {
            emit("posProcessEnded", prmData);
        }

        return {
            showComp,
            startPosProcess,
            theOrder,
            refModal,
        };
    }
});
