import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "accordion-item border-0" }
const _hoisted_3 = { class: "row flex-column flex-sm-row d-flex shadow-sm rounded border border-2 border-gray-300 border-hover mx-auto py-5 ps-3 col" }
const _hoisted_4 = ["id", "data-bs-parent"]
const _hoisted_5 = { class: "accordion-body overlay-hidden scroll-x" }
const _hoisted_6 = ["id"]
const _hoisted_7 = { style: {"display":"none"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HotelContent = _resolveComponent("HotelContent")!
  const _component_HotelRoomType = _resolveComponent("HotelRoomType")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "accordion mb-8",
      id: `accordionPanelsStayOpenExample_${_ctx.propHotelCode}`
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, [
          _createVNode(_component_HotelContent, {
            propHotelPrice: _ctx.propHotelPrice,
            propHotelCode: _ctx.propHotelCode
          }, null, 8, ["propHotelPrice", "propHotelCode"])
        ]),
        _createElementVNode("div", {
          id: `panelsStayOpen-collapseOne_${_ctx.propHotelCode}`,
          class: "accordion-collapse collapse hide border mt-0 ",
          "aria-labelledby": "panelsStayOpen-headingOne",
          "data-bs-parent": `#accordionPanelsStayOpenExample_${_ctx.propHotelCode}`
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "accordion",
              id: `kt_accordion_roomtype_${_ctx.propHotelCode}`
            }, [
              _createVNode(_component_HotelRoomType, {
                propHotelPrice: _ctx.propHotelPrice,
                propHotelCode: _ctx.propHotelCode
              }, null, 8, ["propHotelPrice", "propHotelCode"])
            ], 8, _hoisted_6)
          ])
        ], 8, _hoisted_4)
      ])
    ], 8, _hoisted_1),
    _createElementVNode("p", _hoisted_7, "theHotelPrice: " + _toDisplayString(_ctx.theHotelPrice), 1)
  ], 64))
}