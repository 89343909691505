import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "w-100 d-flex align-items-center justify-content-between" }
const _hoisted_3 = { class: "d-flex flex-column me-3" }
const _hoisted_4 = { class: "text-primary fw-bolder d-block fs-6 " }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "form-check form-check-custom form-check-solid d-flex justify-content-center" }
const _hoisted_7 = ["name", "id", "data-pricecode"]
const _hoisted_8 = ["data-bs-target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlyInfo = _resolveComponent("FlyInfo")!
  const _component_ClassSelection = _resolveComponent("ClassSelection")!

  return (_openBlock(), _createElementBlock("div", {
    class: "p-5 accordion shadow-sm rounded border border-1 border-gray-300 border-hover d-flex flex-column mb-10",
    id: `${'div_Accordion_FlightTicketContainer_'+_ctx.theFlight.FlightCode}`,
    for: "kt_create_account_form_account_type_personal1"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.theFlight.Segments, (each, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "d-flex align-items-center"
          }, [
            _createVNode(_component_FlyInfo, {
              theFlight: _ctx.theFlight,
              theSegment: each
            }, null, 8, ["theFlight", "theSegment"])
          ]))
        }), 128))
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.theFlight.PosterPrice) + " ₺", 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("input", {
            class: "form-check-input border border-primary cls-radio-flightsel",
            type: "radio",
            name: `FlightSel_${_ctx.flightDirection}`,
            value: "",
            id: `${_ctx.getRadioId()}`,
            "data-pricecode": `${_ctx.theFlight.thePrice.PriceCode}`,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.click_Radio_Flight(_ctx.theFlight)))
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("div", {
          class: "btn btn-sm btn-secondary mt-10 d-none",
          "data-bs-toggle": "collapse",
          "data-bs-target": `${'div_Flight_ClassSelection_'+_ctx.theFlight.FlightCode}`,
          "aria-expanded": "true",
          "aria-controls": "differentclass_body_1"
        }, " Class ", 8, _hoisted_8),
        _createElementVNode("div", {
          class: "btn btn-sm btn-secondary mt-10 d-none",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.click_ClassSelection(_ctx.theFlight)))
        }, " Detaylar ")
      ])
    ]),
    _createVNode(_component_ClassSelection, {
      arrServices: _ctx.theFlight.Services,
      theFlight: _ctx.theFlight
    }, null, 8, ["arrServices", "theFlight"])
  ], 8, _hoisted_1))
}