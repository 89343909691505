
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "Hotel-Room-Type-Section",
    components: {
    },
    props: {
        propHotelPrice: {},
        propHotelCode: String,
        propRoomPrice: {},
        propRoomPriceCode: String,
    },
    setup(props) {



        function getAvailableLabel(prmItem) {
            if (prmItem.Availability==="AVAILABLE") {
                return "Müsait";
            }
            return "Müsait Değil";
        }

        function click_HotelPrice() {

            if (AppCore.prsCore_Flight.theStepsBasket.Step2===undefined) {AppCore.prsCore_Flight.theStepsBasket.Step2 = {};}

            AppCore.prsCore_Flight.theStepsBasket.Step2.HotelPrice = props.propHotelPrice;
            AppCore.prsCore_Flight.theStepsBasket.Step2.RoomPrice = props.propRoomPrice;

            AppCore.prsCore_Flight.funcSummary_PrsStepsBasket();

            //store.dispatch(Actions.FLIGHT_RESERVATION_STEPS_BASKET, theStepsBasket.value);


            let vvPayload = {
                "SearchCode" : AppCore.prsCore_Flight.ccvSearchCode,
                "PriceCode": (props.propRoomPrice as any).PriceCode,
                "Type": 'HOTEL'
            };

            console.log("RoomTypeSection vvPayload: "+JSON.stringify(vvPayload));

            store.dispatch(Actions.FLIGHT_RESERVATION_ADD, vvPayload);

            //console.log("theSearchParams: "+ JSON.stringify(AppCore.prsCore_Flight.theSearchParams));
            //console.log("theStepsBasket: "+ JSON.stringify(AppCore.prsCore_Flight.theStepsBasket));
        }

        return {
            click_HotelPrice,
            getAvailableLabel,
            libTools,
        };
    },
});
