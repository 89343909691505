import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoomTypeSection = _resolveComponent("RoomTypeSection")!
  const _component_RoomPriceTable = _resolveComponent("RoomPriceTable")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propHotelPrice.RoomPrice, (each, i) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
      _createVNode(_component_RoomTypeSection, {
        propHotelPrice: _ctx.propHotelPrice,
        propHotelCode: _ctx.propHotelCode,
        propRoomPrice: each,
        propRoomPriceCode: _ctx.getRoomPriceCode(i)
      }, null, 8, ["propHotelPrice", "propHotelCode", "propRoomPrice", "propRoomPriceCode"]),
      _createVNode(_component_RoomPriceTable, {
        propHotelPrice: _ctx.propHotelPrice,
        propHotelCode: _ctx.propHotelCode,
        propRoomPrice: each,
        propRoomPriceCode: _ctx.getRoomPriceCode(i)
      }, null, 8, ["propHotelPrice", "propHotelCode", "propRoomPrice", "propRoomPriceCode"])
    ], 64))
  }), 128))
}