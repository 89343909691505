
import { defineComponent } from "vue";

export default defineComponent({
    name: "Contact Info",
    components: {
    },
    props: {
        propContactInfoType: String,
    },
    setup(props) {
        return {

        };
    },
});
