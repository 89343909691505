
import {defineComponent, onMounted, ref} from "vue";
import HotelContent from "@/components/new-reservation/hotel-list/HotelContent.vue";
import HotelRoomType from "@/components/new-reservation/hotel-list/HotelRoomType.vue";

export default defineComponent({
    name: "Hotel Container ",
    components: {
        HotelRoomType,
        HotelContent,
    },
    props: {
        propHotelPrice: {},
        propHotelCode: String,
    },
    setup(props) {

        let theHotelPrice = ref({});


        onMounted(() => {
            console.log("-------------------")
            theHotelPrice.value = props.propHotelPrice;

            //console.log("propHotelPrice: "+JSON.stringify(props.propHotelPrice));
            //console.log("propHotelPrice theHotelPrice: "+JSON.stringify(theHotelPrice));

        });

        return {
            theHotelPrice,
        };
    },
});
