
import {defineComponent, onMounted} from "vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {dateConvert} from "@/core/filters/datatime";

export default defineComponent({
  name: "Different Class",
  components: {
  },
  props: {
    theFlight: {},
    theSegment: {},
  },
  setup(props) {
    onMounted(() => {
      //
      //console.log("props.theFlight: "+JSON.stringify(props.theFlight));
    });

    return {
      dateConvert, libTools,
    };
  }
});
