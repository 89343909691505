
import { defineComponent } from "vue";

export default defineComponent({
  name: "Different Class",
  components: {
  },
  props: {arrServices: Array, theFlight: {},}

});
