
import {computed, defineComponent, onMounted, ref} from "vue";
import ClassSelection from "@/components/new-reservation/fly-ticket/ClassSelection.vue";
import FlyInfo from "@/components/new-reservation/fly-ticket/FlyInfo.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
  name: "Fly-Ticket-Container",
  components: {
    FlyInfo,
    ClassSelection,
  },
  props: {
    flightDirection: String,
    searchCode: String,
    theFlight: {},
  },
  setup(props) {

    onMounted(() => {
      //
      //console.log("props.theFlight: "+JSON.stringify(props.theFlight));
    });

    function click_Radio_Flight(prmFlight) {
      console.log("click_Radio_Flight prmPriceCode: "+prmFlight.thePrice.PriceCode);
      let vvPayload = {
        "SearchCode" : props.searchCode,
        "PriceCode": prmFlight.thePrice.PriceCode,
        "Type": getType_by_Direction()
      };

      if (AppCore.prsCore_Flight.theStepsBasket.Step1===undefined) {AppCore.prsCore_Flight.theStepsBasket.Step1 = {};}

      if (props.flightDirection==="Dep") {
        AppCore.prsCore_Flight.theStepsBasket.Step1.FlightDep = prmFlight;
      } else {
        AppCore.prsCore_Flight.theStepsBasket.Step1.FlightRet = prmFlight;
      }

      AppCore.prsCore_Flight.funcSummary_PrsStepsBasket();
      store.dispatch(Actions.FLIGHT_RESERVATION_ADD, vvPayload);

    }

    function click_ClassSelection(prmFlight){
      console.log("click_ClassSelection START");
      let div_Flight_ClassSelection = document.getElementById('div_Flight_ClassSelection_'+prmFlight.FlightCode) as HTMLElement;
      console.log("click_ClassSelection div_Flight_ClassSelection_: "+div_Flight_ClassSelection.getAttribute("id"));
      if (div_Flight_ClassSelection!==undefined && div_Flight_ClassSelection!==null) {
        //
      }

      libTools.method.toggleClass('div_Flight_ClassSelection_'+prmFlight.FlightCode, "collapse")
    }

    function getRadioId() {
      return (props.theFlight as any).FlightNo.replace(" ", "") + "_" + (props.theFlight as any).thePrice.PriceCode;
    }

    function getType_by_Direction() {
      if (props.flightDirection==="Dep") {
        return "FLIGHTDEPARTURE";
      }
      return "FLIGHTRETURN";
    }

    return {
      click_Radio_Flight, getRadioId,
      click_ClassSelection,
    }
  }
});
