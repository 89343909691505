

import {defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import {hideModal} from "@/core/helpers/dom";

export default defineComponent({

    setup(props, {emit}) {

        let refModal = ref<null | HTMLElement>(null);

        let theOrder = ref({});
        let theCardInfo = ref({});
        let theBankPosInfo = ref({});

        function showComp(prmCardInfo, prmBankPosInfo, prmAmount, prmCur, prmDescription) {
            console.log("PosNomuPayFormModal.vue showComp START "+JSON.stringify(prmCardInfo));
            theCardInfo.value = prmCardInfo;
            theBankPosInfo.value = prmBankPosInfo;
            startPosProcess(prmAmount, prmCur, prmDescription);
        }

        function startPosProcess(prmAmount, prmCur, prmDescription) {
            window.addEventListener('message', handleMessage, false);

            let vvCustomerName = (document.getElementById("txt_Step5_Name_Rez") as HTMLInputElement).value;
            let vvCustomerSurname = (document.getElementById("txt_Step5_Surname_Rez") as HTMLInputElement).value;
            let vvCustomerEmail = (document.getElementById("txt_Step5_Email_Rez") as HTMLInputElement).value;

            let vvPayload = {
                VoucherNo: AppCore.prsCore_Flight.ccvVoucherNo,
                BankPosID: (theBankPosInfo.value as any).BankPosID,
                InstallmentCount: (theBankPosInfo.value as any).InstallmentCount,
                Amount: prmAmount,
                Cur: prmCur,
                Description: prmDescription,
                CustomerName: vvCustomerName,
                CustomerSurname: vvCustomerSurname,
                CustomerEmail: vvCustomerEmail,
            };

            store.dispatch(Actions.POS_PROCESS_SEND_FORM_NOMUPAY, vvPayload).then((prmData) => {
                console.log("POS_PROCESS_SEND_FORM_NOMUPAY NomuPay prmData");
                console.log(prmData);



                let vvParams =
                    "?alldata=" + JSON.stringify(prmData.data)
                    + "&amount=" + prmAmount
                    + "&cur=" + prmCur
                    + "&customername=" + vvCustomerName
                    + "&customersurname=" + vvCustomerSurname
                    + "&customeremail=" + vvCustomerEmail


                let vvPath = "/#/PosNomuPayForm"+vvParams;
                console.log("vvPath: "+vvPath);

                let divPosNomuPayFormA = document.getElementById("divPosNomuPayFormA") as HTMLElement;

                let ifrmPosNomuPayFormA = document.getElementById("ifrmPosNomuPayFormA") as HTMLIFrameElement;

                if (divPosNomuPayFormA.hasChildNodes() && ifrmPosNomuPayFormA!==null) {
                    divPosNomuPayFormA.removeChild(ifrmPosNomuPayFormA);
                }

                //ReCreate
                ifrmPosNomuPayFormA = document.createElement("iframe");
                ifrmPosNomuPayFormA.setAttribute("id", "ifrmPosNomuPayFormA");
                ifrmPosNomuPayFormA.setAttribute("width", "100%");
                ifrmPosNomuPayFormA.setAttribute("height", "500");
                divPosNomuPayFormA.appendChild(ifrmPosNomuPayFormA);
                ifrmPosNomuPayFormA.src = vvPath;

            });
        }

        function handleMessage(event) {
            if ( event.data!==undefined) {
                console.log("handleMessage A data:  "+event.data);
                if (typeof event.data === "string") {
                    console.log("handleMessage B data:  "+event.data);
                    let vvObj = JSON.parse(event.data);
                    if (vvObj.OrderID!==undefined ) {
                        console.log("handleMessage vvObj:  "+JSON.stringify(vvObj));
                        console.log("handleMessage OrderID:  "+vvObj.OrderID+" StatusCode: "+ vvObj.StatusCode);
                        theOrder.value = vvObj;
                        if ( vvObj.StatusCode=== "1") {
                            hideModal(refModal.value);
                            endProcess({});
                        }

                    }
                }

            }
        }

        function endProcess(prmData) {
            emit("posProcessEnded", prmData);
        }

        return {
            refModal,
            theOrder,
            showComp,
        };
    }

});

